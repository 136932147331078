import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoTitle from '../../assets/images/logo-s.png'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'
import Logo from './Logo'
import './index.scss'
import ReactTyped from "react-typed";

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const nameArray = ['F', 'r', 'a', 'n', 'c', 'e', 's', 'c', 'o', ' ', ' B','a', 'l', 'd', 'i', ' ',]
  const jobArray = [
    'F', 
    'u', 
    'l', 
    'l',
    ' ',
    'S', 
    't', 
    'a', 
    'c', 
    'k', 
    ' ', 
    'D', 
    'e', 
    'v', 
    'e', 
    'l', 
    'o', 
    'p', 
    'e', 
    'r'
  ]
  
  useEffect(() =>{
    setTimeout(() => {
      return setLetterClass('text-animate-hover')
    }, 5000);
  })
  

  return (
    <div className="container home-page">
      <div className="text-zone">
        <h1>
        <span className={letterClass}>H</span> 
        <span className={`${letterClass} _12`}>i,</span> 
        <br />
        <span className={`${letterClass} _13`}>I</span> 
        <span className={`${letterClass} _14`}>'m</span><span> </span>
        <AnimatedLetters letterClass={letterClass} 
        strArray={nameArray} 
        idx={15}
        />
        <br />
        <AnimatedLetters letterClass={letterClass} 
        strArray={jobArray} 
        idx={22}
        />
        </h1>
        <h2><ReactTyped
          strings={["Front-end", "Back-end", "App Developer"]}
          typeSpeed={100}
          loop
          backSpeed={20}
          cursorChar=">"
          showCursor={true}
        /></h2>
        <Link to="/about" className='flat-button'>About Me</Link>
      </div>
      <Logo />
      <Loader type="line-spin-fade-loader" />
    </div>
  )
}

export default Home

import WorkInProgresS from '../../../assets/images/workinprogress.gif'
import './index.scss'

const WorkInProgress = () => {



  return (
    <div className="work-in-progress-container">
      <img
        className="solid-work-in-progress"
        src={WorkInProgresS}
        alt="JavaScript,  Developer"
      />
    </div>
  )
}

export default WorkInProgress
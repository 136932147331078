import { useEffect, useState } from 'react'
import ReactTyped from "react-typed";
import {
  faAngular,
  faGitAlt,
  faJsSquare,
  faReact,
  faLaravel,
  faPhp
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WorkInProgress from './WorkInProgress'
import './index.scss'

const Portfolio = () => {

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
          <ReactTyped
          strings={["Work in progress...", "Stay tuned", ":)     "]}
          typeSpeed={100}
          loop
          backSpeed={20}
          cursorChar=">"
          showCursor={true}
        />
          </h1>
       </div>
       </div>
       <WorkInProgress />
      <Loader type="line-spin-fade-loader" />
    </>
  )
}

export default Portfolio
import { useEffect, useState } from 'react'
import {
  faAngular,
  faGitAlt,
  faJsSquare,
  faReact,
  faLaravel,
  faPhp
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
          I was born on May 18, 1992 in Sansepolcro. A small town in the province of Arezzo in Italy.
          After graduating as a commercial computer expert, I studied computer engineering at the University of Florence.
          Unfortunately I didn't get my degree but starting from 2017 I started working as a developer. (I'm thinking of finishing my studies at some private university).</p>

          <p>I specialize in software development, especially web and app development. I work at Blucloud s.r.l.
          BluCloud Srl is a technological company born in 2014 from the experience of over fifteen years of its founders in the sector of development, marketing and provision of traditional and cloud IT services.

          <p><b>My knowledge:</b><br></br></p>
          - Frontend Developer (Angular, Angularjs, Vue.js, React.js, Javascript, jQuery, HTML5, CSS3)<br></br>
          - Backend Developer (NodeJS, PHP, MySQL, MariaDB, Java, C++, .Net, Asp.Net,)<br></br>
          - Sysadmin (LINUX)<br></br>
              Advanced knowledge of computer and network hardware architectures<br></br>
              Advanced knowledge of the operation and configuration of computer networks<br></br>
          - Versioning: Git, Atlassian Software (BitBucket, Jira)<br></br>

          <p><b>Actually:</b></p>
          - Development of Web Applications<br></br>
          - Development of Mobile Applications in Android and iOS <br></br>
          - Integrations and API development
          </p>
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['E', 'x', 'p', 'e', 'r', 'i', 'e', 'n', 'c', 'e']}
              idx={15}
            />
          </h1>
          <div className="timeline">
            <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
              <div className="timeline__event__icon ">
                Jan 2021 - Present
              </div>
              <div className="timeline__event__date">
                Blucloud srl
              </div>
              <div className="timeline__event__content ">
                <div className="timeline__event__title">
                  Junior Full Stack Developer - FULL TIME
                </div>
                <div className="timeline__event__description">
                  <p>My position was confirmed and I was hired full time. I now deal with more complex implementations, optimizing resources and work processes.
                     I also deal App development in particular for iOS and Android.</p>
                </div>
              </div>
            </div>
            <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
              <div className="timeline__event__icon">
                Jun 2020 - Dec 2020
              </div>
              <div class="timeline__event__date">
                Blucloud srl
              </div>
              <div class="timeline__event__content">
                <div class="timeline__event__title">
                  Junior Full Stack Developer - INTERNSHIP
                </div>
                <div className="timeline__event__description">
                  <p>Six months as an intern as a web developer, front-end, and back-end.
  Digital marketing activities also integrated with customer management operations.</p>
                </div>
              </div>
            </div>
            <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
              <div className="timeline__event__icon">
                Aug 2019 - Mar 2020
              </div>
              <div className="timeline__event__date">
                  Blucloud srl
              </div>
              <div className="timeline__event__content">
                <div className="timeline__event__title">
                  Junior Full Stack Developer - SEASONAL
                </div>
                <div className="timeline__event__description">
                  <p>In this experience I started as an external collaborator in the Blucloud srl company with software developments.
  During              this period I took the course at the Confcommercio of Arezzo which allowed me to achieve the professional qualification as a Developer.</p>
                </div>

              </div>
            </div>
            <div className="timeline__event animated fadeInUp timeline__event--type1">
              <div className="timeline__event__icon">
                Oct 2017 - May 2018
              </div>
              <div className="timeline__event__date">
                IPLABS srl
              </div>
              <div className="timeline__event__content">
                <div className="timeline__event__title">
                  Junior WEB Developer - Apprenticeship
                </div>
                <div className="timeline__event__description">
                  <p><ul>
                    <li>Develop features, from the initial concept to the final configuration, according to customer
                      specifications in web apps provided</li>
                    <li>Test the correct functioning of the site or web app in all its features (testing)</li>
                    <li> Fix errors found (debug)</li>
                  </ul></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faAngular} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faLaravel} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faPhp} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="line-spin-fade-loader" />
    </>
  )
}

export default About

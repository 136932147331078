import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import AnimatedLetters from '../AnimatedLetters'
import Swal from 'sweetalert2'
import './index.scss'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const refForm = useRef()
  useEffect(() => {
    setTimeout(() => {
      return setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  const sendEmail = (e) => {
    
    e.preventDefault()

    emailjs
      .sendForm('service_byjiqmd', 'template_azij3sk', refForm.current, 'JaezTpl702pIkmnCU')
      .then(
        () => {
          Swal.fire({
            title:'Send mail',
            icon: 'success',
            text: 'Email correctly sent',
            confirmButtonText: 'Ok',
            showConfirmlButton: true
          }).then( () => {
            window.location.reload(false);
          })
        },
        () => {
          Swal.fire({
            title:'Send mail',
            icon: 'error',
            text: 'Email sendin error, please try again',
            confirmButtonText: 'Ok',
            showConfirmlButton: true
          }).then( () => {
            window.location.reload(false);
          })
        }
      )
  }


    return (
      <>
        <div className='container contact-page'>
            <div className='text-zone'>
              <h1>
                  <AnimatedLetters 
                  letterClass={letterClass}
                  strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
                  idx={15} 
                  />
              </h1>
              <p>
              I am interested in freelance opportunities - especially on ambitious
              or large projects. However, if you have any other requests or
              questions, don't hesitate to contact me using below form either.
              </p>
              <div className="contact-form">
                  <form ref={refForm} onSubmit={sendEmail}>
                      <ul>
                        <li className="half">
                          <input placeholder="Name" type="text" name="name" required />
                        </li>
                        <li className="half">
                          <input placeholder="Email" type="email" name="email" required />
                        </li>
                        <li>
                          <input placeholder="Subject" type="text" name="subject" required />
                        </li>
                        <li>
                          <textarea
                          placeholder="Message"
                          name="message"
                          required
                          ></textarea>
                        </li>
                        <li>
                           <input type="submit" className="flat-button" value="SEND"/>
                        </li>
                      </ul>
                  </form>
              </div>
            </div>
            <div className="info-map">
                Francesco Baldi,
                <br />
                Italia,
                <br />
                Viale Galileo Galilei 22 <br />
                Sansepolcro Arezzo <br />
                <br />
                <span>francesco.baldi92@gmail.com</span>
              </div>
              <div className="map-wrap">
                <MapContainer center={[43.57309, 12.14805]} zoom={13}>
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <Marker position={[43.57309, 12.14798]}>
                    <Popup>Francesco  lives here, come over for a beer togheter :)</Popup>
                  </Marker>
                </MapContainer>
              </div>
        </div>
      <Loader type="line-spin-fade-loader" />
      </>
    )
} 

export default Contact